"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviewFormat = exports.TemplateType = exports.PostTypeEnum = exports.ScreenEnum = exports.ViewEnum = exports.ProfileScreen = exports.AdminScreen = exports.EditorScreen = exports.UserScreen = exports.DisplayMode = exports.Languages = void 0;
var Languages;
(function (Languages) {
    Languages["de"] = "de";
    Languages["en"] = "en";
    Languages["es"] = "es";
    Languages["it"] = "it";
})(Languages = exports.Languages || (exports.Languages = {}));
var DisplayMode;
(function (DisplayMode) {
    DisplayMode["popUp"] = "popUp";
    DisplayMode["newScreen"] = "newScreen";
    DisplayMode["additional"] = "additional";
})(DisplayMode = exports.DisplayMode || (exports.DisplayMode = {}));
var UserScreen;
(function (UserScreen) {
    UserScreen["dashboard"] = "dashboard";
    UserScreen["facebook"] = "facebook";
    UserScreen["statistics"] = "statistics";
    UserScreen["topics"] = "topics";
    UserScreen["help"] = "help";
})(UserScreen = exports.UserScreen || (exports.UserScreen = {}));
var EditorScreen;
(function (EditorScreen) {
    EditorScreen["dashboard"] = "dashboard";
    EditorScreen["statistics"] = "statistics";
    EditorScreen["accounts"] = "accounts";
    EditorScreen["images"] = "images";
    EditorScreen["topics"] = "topics";
    EditorScreen["quiz"] = "quiz";
    EditorScreen["imprint"] = "imprint";
    EditorScreen["help"] = "help";
    EditorScreen["announcement"] = "announcement";
})(EditorScreen = exports.EditorScreen || (exports.EditorScreen = {}));
var AdminScreen;
(function (AdminScreen) {
    AdminScreen["accounts"] = "accounts";
    AdminScreen["unlinkedPages"] = "unlinkedPages";
})(AdminScreen = exports.AdminScreen || (exports.AdminScreen = {}));
var ProfileScreen;
(function (ProfileScreen) {
    ProfileScreen["settings"] = "settings";
    ProfileScreen["privacy"] = "privacy";
    ProfileScreen["logout"] = "logout";
})(ProfileScreen = exports.ProfileScreen || (exports.ProfileScreen = {}));
var ViewEnum;
(function (ViewEnum) {
    ViewEnum["overview"] = "overview";
    ViewEnum["template"] = "template";
    ViewEnum["templateDetails"] = "templateDetails";
    ViewEnum["templateUser"] = "templateUser";
    ViewEnum["calendar"] = "calendar";
    ViewEnum["preview"] = "preview";
    ViewEnum["single"] = "single";
})(ViewEnum = exports.ViewEnum || (exports.ViewEnum = {}));
var ScreenEnum;
(function (ScreenEnum) {
    ScreenEnum["xs"] = "xs";
    ScreenEnum["sm"] = "sm";
    ScreenEnum["md"] = "md";
    ScreenEnum["lg"] = "lg";
    ScreenEnum["xl"] = "xl";
})(ScreenEnum = exports.ScreenEnum || (exports.ScreenEnum = {}));
var PostTypeEnum;
(function (PostTypeEnum) {
    PostTypeEnum["PHOTO"] = "PHOTO";
    PostTypeEnum["LINK"] = "LINK";
    PostTypeEnum["VIDEO"] = "VIDEO";
    PostTypeEnum["MESSAGE"] = "MESSAGE";
})(PostTypeEnum = exports.PostTypeEnum || (exports.PostTypeEnum = {}));
var TemplateType;
(function (TemplateType) {
    TemplateType["suggestion"] = "suggestion";
    TemplateType["posting"] = "posting";
    TemplateType["newPost"] = "newPost";
})(TemplateType = exports.TemplateType || (exports.TemplateType = {}));
var PreviewFormat;
(function (PreviewFormat) {
    PreviewFormat["square"] = "square";
    PreviewFormat["origin"] = "origin";
})(PreviewFormat = exports.PreviewFormat || (exports.PreviewFormat = {}));
