"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnnouncementTemplate = exports.getActiveAnnouncement = exports.getPnrUser = exports.getImprint = exports.isUserGooglePageConnected = exports.isUserInstagramPageConnected = exports.isUserFacebookPageConnected = exports.getUserPages = exports.getTokenChanged = exports.getConnectedLinkedInPage = exports.getConnectedGooglePage = exports.getConnectedInstagramPage = exports.getConnectedFacebookPage = exports.getConnectionChanged = exports.getInstagramPages = exports.getFacebookPages = exports.getGoogleConnectHash = exports.getInstagramConnectHash = exports.getFacebookConnectHash = exports.getGoogleRedirect = exports.getInstagramRedirect = exports.getFacebookRedirect = exports.getNavigationItem = exports.getNavigationList = exports.getDevelopmentSystem = exports.getLicensePermissions = exports.getLanguages = exports.getUser = exports.getTheme = exports.getInstagramNumberOfPosts = exports.namedRequestData = exports.namedRequestStatus = exports.namedRequestInProgress = void 0;
var reselect_1 = require("reselect");
exports.namedRequestInProgress = (0, reselect_1.createSelector)([
    function (state) { return state.commonStore.requests; },
    function (state, type) { return type; },
], function (state, type) {
    return state.filter(function (request) { return request.type === type && request.inProgress; }).length > 0;
});
exports.namedRequestStatus = (0, reselect_1.createSelector)([
    function (state) { return state.commonStore.requests; },
    function (state, type) { return type; },
], function (state, type) {
    var _a;
    return (_a = state.find(function (request) { return request.type === type; })) === null || _a === void 0 ? void 0 : _a.status;
});
exports.namedRequestData = (0, reselect_1.createSelector)([
    function (state) { return state.commonStore.requests; },
    function (state, type) { return type; },
], function (state, type) {
    return state.find(function (request) { return request.type === type; });
});
exports.getInstagramNumberOfPosts = (0, reselect_1.createSelector)(function (state) { return state.commonStore.instagramNumberOfPosts; }, function (state) { return state; });
exports.getTheme = (0, reselect_1.createSelector)(function (state) { return state.commonStore.theme; }, function (state) { return state; });
exports.getUser = (0, reselect_1.createSelector)(function (state) { return state.commonStore.user; }, function (state) { return state; });
exports.getLanguages = (0, reselect_1.createSelector)(function (state) { return state.commonStore.languages; }, function (state) { return state; });
exports.getLicensePermissions = (0, reselect_1.createSelector)(function (state) { return state.commonStore.licensePermissions; }, function (state) { return state; });
exports.getDevelopmentSystem = (0, reselect_1.createSelector)(function (state) { return state.commonStore.developmentSystem; }, function (state) { return state; });
exports.getNavigationList = (0, reselect_1.createSelector)(function (state) { return state.commonStore.navigationList; }, function (state) { return state; });
exports.getNavigationItem = (0, reselect_1.createSelector)(function (state) { return state.commonStore.navigationItem; }, function (state) { return state; });
exports.getFacebookRedirect = (0, reselect_1.createSelector)(function (state) { return state.commonStore.facebookRedirect; }, function (state) { return state; });
exports.getInstagramRedirect = (0, reselect_1.createSelector)(function (state) { return state.commonStore.instagramRedirect; }, function (state) { return state; });
exports.getGoogleRedirect = (0, reselect_1.createSelector)(function (state) { return state.commonStore.googleRedirect; }, function (state) { return state; });
exports.getFacebookConnectHash = (0, reselect_1.createSelector)(function (state) { return state.commonStore.facebookConnectHash; }, function (state) { return state; });
exports.getInstagramConnectHash = (0, reselect_1.createSelector)(function (state) { return state.commonStore.instagramConnectHash; }, function (state) { return state; });
exports.getGoogleConnectHash = (0, reselect_1.createSelector)(function (state) { return state.commonStore.googleConnectHash; }, function (state) { return state; });
exports.getFacebookPages = (0, reselect_1.createSelector)(function (state) { return state.commonStore.facebookPages; }, function (state) { return state; });
exports.getInstagramPages = (0, reselect_1.createSelector)(function (state) { return state.commonStore.instagramPages; }, function (state) { return state; });
exports.getConnectionChanged = (0, reselect_1.createSelector)(function (state) { return state.commonStore.connectionChanged; }, function (state) { return state; });
exports.getConnectedFacebookPage = (0, reselect_1.createSelector)(function (state) { return state.commonStore.connectedFacebookPage; }, function (state) { return state; });
exports.getConnectedInstagramPage = (0, reselect_1.createSelector)(function (state) { return state.commonStore.connectedInstagramPage; }, function (state) { return state; });
exports.getConnectedGooglePage = (0, reselect_1.createSelector)(function (state) { return state.commonStore.connectedGooglePage; }, function (state) { return state; });
exports.getConnectedLinkedInPage = (0, reselect_1.createSelector)(function (state) { return state.commonStore.connectedLinkedInPage; }, function (state) { return state; });
exports.getTokenChanged = (0, reselect_1.createSelector)(function (state) { return state.commonStore.tokenChanged; }, function (state) { return state; });
exports.getUserPages = (0, reselect_1.createSelector)(function (state) { return state.commonStore; }, function (commonStore) { return commonStore.userPages; });
var isUserFacebookPageConnected = function (id) { return (0, reselect_1.createSelector)(function (state) { return state.commonStore.userPages; }, function (userPages) { return !!(userPages === null || userPages === void 0 ? void 0 : userPages.find(function (up) { return up.networkPageId === id; })); }); };
exports.isUserFacebookPageConnected = isUserFacebookPageConnected;
var isUserInstagramPageConnected = function (id) { return (0, reselect_1.createSelector)(function (state) { return state.commonStore.userPages; }, function (userPages) { return !!(userPages === null || userPages === void 0 ? void 0 : userPages.find(function (up) { return up.networkPageId === id; })); }); };
exports.isUserInstagramPageConnected = isUserInstagramPageConnected;
var isUserGooglePageConnected = function (id) { return (0, reselect_1.createSelector)(function (state) { return state.commonStore.userPages; }, function (userPages) { return !!(userPages === null || userPages === void 0 ? void 0 : userPages.find(function (up) { return up.networkPageId === id; })); }); };
exports.isUserGooglePageConnected = isUserGooglePageConnected;
exports.getImprint = (0, reselect_1.createSelector)(function (state) { return state.commonStore; }, function (commonStore) { return commonStore.imprint; });
exports.getPnrUser = (0, reselect_1.createSelector)(function (state) { return state.commonStore; }, function (commonStore) { return commonStore.pnrUser; });
exports.getActiveAnnouncement = (0, reselect_1.createSelector)(function (state) { return state.commonStore; }, function (commonStore) { return commonStore.activeAnnouncement; });
exports.getAnnouncementTemplate = (0, reselect_1.createSelector)(function (state) { return state.commonStore; }, function (commonStore) { return commonStore.announcementTemplate; });
